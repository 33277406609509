import React from 'react'
import { css } from '@emotion/core'
import { bpMaxSM } from '../lib/breakpoints'
import Container from './Container'

const Footer = ({ author, noSubscribeForm }) => (
  <footer>
    <Container
      css={css`
        padding-top: 0;
        ${bpMaxSM} {
          padding-top: 0;
        }
      `}
    >
      <div
        css={css`
          text-align: center;
        `}
      >
        <h6
          css={css`
            margin-top: 0.7rem;
            margin-bottom: 0.7rem;
            line-height: 1.5;
            font-size: 0.7rem;
          `} 
        >
          © 2018 <a href="https://multiplestates.co.uk/" title="web site design and devleopment in Brighton">Multiple States Ltd</a>. Registered in England and Wales. Company No. 9102184
        </h6>

        <h6
          css={css`
            margin-top: 0.7rem;
            line-height: 1.5;
            font-size: 0.7rem;
          `} 
        >
          <a href="https://multiplestates.co.uk/privacy-cookie-policy/">Privacy &amp; Cookie Policy</a>
        </h6>
        
      </div>
    </Container>
  </footer>
)

export default Footer
